import React from 'react'
import styles from '../styles/BannerSection.module.css'
import { Link } from "react-router-dom";

import banner_man from '../../../assets/image/landing-images/surprised-man-talking-mobile-white-background_1368-4352-removebg-preview 1.png'
import ToolTip1 from '../../../assets/image/landing-images/Group 26.png'
import ToolTip2 from '../../../assets/image/landing-images/Group 25.png'
import ToolTip3 from '../../../assets/image/landing-images/Group 24.png'
import ToolTip4 from '../../../assets/image/landing-images/Group 26.png'
import { IoChevronDown } from "react-icons/io5";
import { FaArrowDown } from "react-icons/fa6";

const BannerSection = () => {
    return (
        <div className=''>
            <section className={styles.main_container}>
                <img src={ToolTip1} alt="" className={styles.tootltip_img1} />
                <img src={ToolTip3} alt="" className={styles.tootltip_img3} />
                <img src={ToolTip2} alt="" className={styles.tootltip_img2} />
                <img src={ToolTip4} alt="" className={styles.tootltip_img4} />
                <div className={styles.bg_img_container}>
                    <div className={styles.main_img_container}>
                        <img src={banner_man} alt="" />
                    </div>

                    <div className={styles.banner_text_wrapper}>
                        <div className={styles.banner_heading}>
                            <h1>Where the world does markets.</h1>
                        </div>
                        <div className={styles.banner_text_content}>
                            <p>Streamline your business growth with <span>ease.</span> </p>
                            <button>Join Us</button>
                        </div>
                    </div>

                    <div className={styles.down_arrow}>
                        <span><IoChevronDown /></span>
                    </div>

                    <div className={styles.product_intro_link}>
                        <Link>Product Intro</Link>
                        <span><FaArrowDown /></span>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BannerSection