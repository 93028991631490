import React from 'react'
import FeatureSlider from '../../../components/slider/FeatureSlider'
import styles from '../styles/FeatureSection.module.css'
import fireIcon from '../../../assets/image/landing-images/fi_14261136.png'
import { FaCircleChevronRight } from "react-icons/fa6";
import arrow from '../../../assets/image/landing-images/green_arrow.png'
import VideoPlayIcon from '../../../assets/image/landing-images/vdo-play-icon.png'

const FeatureSection = () => {
    return (
        <div>
            <section className={styles.features_main_container}>
                <div className={styles.slider_header}>
                    <img src={fireIcon} alt="" />
                    <h6>Top stocks in the market.</h6>
                </div>
                <FeatureSlider />
                <div className={styles.features_desc_main_section}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className={styles.features_desc_text}>
                                    <h1><span className={styles.desc_text_feature}>Features.</span> Personal <span className={styles.desc_text_degree}>360°</span> investments for everyone</h1>
                                    <p>
                                        Trading and leverage are powerful tools in the arsenal of online traders. At its essence, margin trading allows traders to borrow funds to purchase more shares than their available capital would normally permit.
                                    </p>
                                    <div className={styles.features_read_more}>
                                        <img src={arrow} alt="" />
                                        <p>Read More</p>
                                    </div>
                                    <div className={styles.trading_btn}>
                                        <span>Start Trading</span>
                                        <p><FaCircleChevronRight /></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className={styles.features_img_section}>
                                    <div className={styles.video_ctrl_txt_btn}>
                                        <img src={VideoPlayIcon} alt="" />
                                        <p>Watch our product video</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FeatureSection