import React from 'react'
import Nav from '../../../components/nav/Nav'
import BannerSection from './BannerSection'
import ServiceSection from './ServiceSection'
import FeatureSection from './FeatureSection'
import SaleSection from './SaleSection'
import TradingFeatures from './TradingFeatures'
import TestimonialSection from './TestimonialSection'
import PricingSection from './PricingSection'
import FooterSection from './FooterSection'
import Navbar from './Navbar'

const LandingPage = () => {
    return (
        <div>
            {/* <Nav /> */}
            <Navbar />
            <BannerSection />
            <ServiceSection />
            <FeatureSection />
            <SaleSection />
            <TradingFeatures />
            <TestimonialSection />
            <PricingSection />
            <FooterSection />
        </div>
    )
}

export default LandingPage