import React from 'react';

const Card = ({ data }) => {
  return (
    <div className="card">
      {/* Your card content goes here */}
      <p>{data.stock}  {data.threshold}</p>
    </div>
  );
};

export default Card;
