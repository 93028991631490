import React,{useEffect} from 'react';
import { createChart } from 'lightweight-charts';

const CandleChart =() => {
  

  useEffect(() => {
   renderChart();
  }, [])
  

  /**
   * Function to render a line chart
   */
  const renderChart = ()=>{
    const container = document.getElementById('candle-chart-container');
    const candleChart = createChart(container, { width: 800, height: 400 });
    const candlestickSeries = candleChart.addCandlestickSeries();
    //Temp Data
    const candlestickData = [
        { time: '2018-12-22', open: 75.16, high: 82.84, low: 36.16, close: 45.72 },
        { time: '2018-12-23', open: 45.12, high: 53.90, low: 45.12, close: 48.09 },
        { time: '2018-12-24', open: 60.71, high: 60.71, low: 53.39, close: 59.29 },
        { time: '2018-12-25', open: 68.26, high: 68.26, low: 59.04, close: 60.50 },
        { time: '2018-12-26', open: 67.71, high: 160.85, low: 10.67, close: 155.04 },
        { time: '2018-12-27', open: 91.04, high: 121.40, low: 82.70, close: 111.40 },
        { time: '2018-12-28', open: 111.51, high: 142.83, low: 103.34, close: 131.25 },
        { time: '2018-12-29', open: 131.33, high: 151.17, low: 40.68, close: 40.43 },
        { time: '2018-12-30', open: 106.33, high: 110.20, low: 90.39, close: 98.10 },
        { time: '2018-12-31', open: 109.87, high: 114.69, low: 85.66, close: 111.26 },
          ];
    
    // Add the candlestick data to the series
    candlestickSeries.setData(candlestickData);
    candleChart.timeScale().fitContent();
  }  

  return (
    <>
        <div id="candle-chart-container"></div>
    </>
        
  )
}

export default CandleChart