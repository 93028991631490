import React, { useRef } from "react";
import { useState, useEffect } from "react";
import styles from "./Signup.module.css";
import { useHistory } from 'react-router-dom';
import Nav from '../../components/nav/Nav'

import { doSignup , selectsignupSuccess} from "./SignupSlice";
import { useDispatch, useSelector } from "react-redux";

export const Signup = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const signUpStatus = useSelector(selectsignupSuccess);
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const pwdRule = useRef({rule1:false, rule2:false, rule3:false, result:false});

  useEffect(() => {
    if(signUpStatus) history.push('/login');
  }, [signUpStatus,history])
  
  /**
   * Function to handle form input changes.
   * @param {*} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'password'){
      const result = validatePassword(value);
      // const {rule1, rule2, rule3} = result;
      pwdRule.current = result;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * Function to handle the signup button
   */
  const signUp = () => {
    if (validateSignupData()){
      const req = {
        firstName : formData.name,
        lastName : formData.name,
        email : formData.email,
        password : formData.password
      }
      dispatch(doSignup(req));
    }
  };

  const validatePassword = (password) => {
    const hasNumber = /\d/.test(password);
    const hasAlphabet = /[a-zA-Z]/.test(password);
    const hasMinLength = password.length >= 6;
    let obj = {
      rule1 : hasMinLength,
      rule2 : hasAlphabet,
      rule3 : hasNumber,
      result : (hasAlphabet && hasMinLength && hasNumber)
    }
    return obj;
  };

  const validateForm=()=>{
    const {name, mobileNumber, email, password, confirmPassword} = formData;
    if(isNameValid(name) && isMobileNumberValid(mobileNumber) && isEmailValid(email) && password && confirmPassword && (password === confirmPassword) && pwdRule.current.result) return true;
    return false;
  }

  const isEmailValid = (email)=> {
    if(!email) return false;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  const isMobileNumberValid = (number)=> {
    if(!number) return false;
    const numberPattern = /^[0-9]{10}$/;
    return numberPattern.test(number);
  }

  const  isNameValid = (name) => {
    if(!name) return false;
    const namePattern = /^[a-zA-Z\s]+$/;
    return namePattern.test(name);
  }
  

  

  /**
   * Function to validate the user inputs.
   * @returns boolean
   */
  const validateSignupData = () => {
    return true;
  };

  return (
    <div className={`row ${styles.container}`}>
      <Nav/>
      <div>
        <h5>Sign Up</h5>
      </div>
      <div className={styles.form_wrapper}>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <span className={styles.form_error}>{(!isNameValid(formData.name) && formData.name) ? 'Name can only contain alphabets and spaces.' : null}</span>
          </div>
          <div className="form-group">
            <label htmlFor="mobileNumber">Mobile Number</label>
            <input
              type="text"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
            <span className={styles.form_error}>{(!isMobileNumberValid(formData.mobileNumber) && formData.mobileNumber) ? 'Invalid Mobile Number' : null}</span>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <span className={styles.form_error}>{(!isEmailValid(formData.email) && formData.email) ? 'Invalid Email' : null}</span>

          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <ul className={styles.password_rules}>
              <li className={pwdRule.current.rule1 ? styles.color_green : null}>Minimum length should be 6</li>
              <li className={pwdRule.current.rule2 ? styles.color_green : null}>Should contain a alphabet</li>
              <li className={pwdRule.current.rule3 ? styles.color_green : null}>Should contain a number</li>
            </ul>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            <span className={styles.form_error}>{((formData.confirmPassword) && (formData.password !== formData.confirmPassword)) ? 'Password not matching' : null}</span>
          </div>
          <input
            type="button" disabled={!validateForm()}
            className="btn btn-primary mt-2"
            onClick={signUp}
            value='Sign Up'
          />
            
          
        </form>
      </div>
    </div>
  );
};
