import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Counter } from './features/counter/Counter';
import Login from './features/login/Login';
import Home from './features/home/Home';
import './App.css';
import { Loader } from './components/loader/Loader';
import { Signup } from './features/signup/Signup';
import LandingPage from './features/landing/sections/LandingPage';


function App() {
  return (
    <div className="">
      <Loader />
      <Router>
        <Switch>

          <Route path='/login' component={Login} exact></Route>
          <Route path='/signup' component={Signup} exact></Route>
          <Route path='/counter' component={Counter} exact></Route>
          <Route path='/home' component={Home}></Route>

          <Route path='/landing-page' component={LandingPage} exact ></Route>

          {/* <Route path='/'>
          <Redirect to='/login'/>
        </Route>
        <Route path="/*">
          <Redirect to='/login'/>
        </Route> */}

          <Route path='/'>
            <Redirect to='/landing-page' />
          </Route>
          <Route path="/*">
            <Redirect to='/landing-page' />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;


