import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchDipStocks, selectDipStocks } from "./DipSlice";
import { selectUserWatchlist, selectIndices } from "../watchlist/WatchlistSlice";
import styles from "./Dip.module.css";
import { Table, Pagination } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../../util/css/Table.css";
import Grid from "../../components/grid/Grid";

export const Dip = () => {
  const [dipThreshold, setDipThreshold] = useState(1);
  const [dipDuration, setDipDuration] = useState(3);
  const dispatch = useDispatch();
  const dip_stocks = useSelector(selectDipStocks);
  const watchlists = useSelector(selectUserWatchlist) || [];
  const indices = useSelector(selectIndices) || [];
  const months_array=[1,2,3,4,5,6,7,8,9,10,11,12];
  const [watchlistSelected, setWatchlistSelected] = useState('');
  const [indexSelected, setIndexSelected] = useState('');

  useEffect(() => {  
    //TO DO - Validate selection. Handle edge cases/ watchlist/index
    //Set a default watchlist selected
    setIndexSelected(indices[0].indexUId);
    console.log(indices[0].indexUId);
    const req = {
      months: dipDuration,
      threshold: dipThreshold,
      indexUId: indices[0].indexUId
    };
    dispatch(fetchDipStocks(req));
  }, [dispatch]);


  const fetchCustomDipList = ()=>{
    const req = {
      months: parseInt(dipDuration, 10),
      threshold: parseInt(dipThreshold, 10),
    };
    if(watchlistSelected) req.watchlistUId = watchlistSelected;
    if(indexSelected) req.indexUId = indexSelected;

    console.log(req)
    dispatch(fetchDipStocks(req));
  }

  const handleGroupSelection=(value, flag)=>{
    if(flag === "W"){
      setWatchlistSelected(value);
      setIndexSelected("");
    }else{
      setWatchlistSelected("");
      setIndexSelected(value);
    }
  }

  return (
    <div className={`row ${styles.wrapper}`}>
      <div className={styles.feature_head}>
        <h3>Invest In Dips</h3>
      </div>
      <div className={styles.filter_section}>
        <div className="form-row row">
          <div className="form-group col-md-3">
            <label>Watchlist</label>
            <select className="form-control" value={watchlistSelected} onChange={(event)=>handleGroupSelection(event.target.value,'W')}>
              <option></option>
              {
                watchlists.map((item,index)=>{
                  return(
                    <option key={index} value={item.watchlistUId}>{item.watchlistName}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group col-md-3">
            <label>Index</label>
            <select className="form-control" value={indexSelected} onChange={(event)=>handleGroupSelection(event.target.value,"I")}>
              <option></option>
              {
                indices.map((item,index)=>{
                  return(
                    <option key={index} value={item.indexUId}>{item.indexName}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group col-md-3">
            <label>Dip</label>
            <input type="number" className="form-control" value={dipThreshold} onChange={(event)=>setDipThreshold(event.target.value)}/>
          </div>
          <div className="form-group col-md-3">
            <label>Months</label>
            <select className="form-control" value={dipDuration} onChange={(event)=>setDipDuration(event.target.value)}>
              {
                months_array.map((month)=>{
                  return(
                    <option key={month} value={month}>{month}</option>
                  )
                })
              }
            </select>
          </div>
          <div className={`form-group col-md-3 ${styles.centered_button}`}>
            <input type="button" value='VIEW' className="btn btn-success" onClick={fetchCustomDipList}></input>
          </div>
        </div>
      </div>
      <div>
        {dip_stocks && (
          <>
          <Grid source = {dip_stocks}/>
          </>
        )}
      </div>
    </div>
  );
};
