import React,{useState} from "react";
import Card from "./Card";
import './Grid.css';
const Grid = (data) => {


  const sampleData = data.source ? data.source : [];
  
  const itemsPerPage = 12; 
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(sampleData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sampleData.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="thickwallet-grid-container">
      <div className="grid">
        {currentItems.map((item,index) => (
          <Card key={index} data={item} />
        ))}
      </div>
      {/* Pagination below */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Grid;
