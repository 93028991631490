import axios from 'axios';
import { showLoading, hideLoading  } from '../features/loader/loaderSlice';
import { store } from '../app/store';
import { Util } from './util';

axios.interceptors.request.use(
  config => {
    store.dispatch(showLoading());
    //Get token
    const accessToken = store.getState().login?.userObject?.accessToken;
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken
      config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
    function (response) {
      store.dispatch(hideLoading()); 
      return response;
    },
    function (error) {
      store.dispatch(hideLoading()); 
      //Handle client error codes
      if(error.response.status >= 400 && error.response.status < 500){
          if(error.response?.data?.error?.message){
            Util.showAlert(Util.alert_types.error, 'ERROR', error.response?.data?.error?.message );
          }else{
            Util.showAlert(Util.alert_types.error, 'ERROR', 'Technical Error!Please try again after some time!' );
          }
      }
      if(error.response.status === 500){
        Util.showAlert(Util.alert_types.error, 'ERROR', 'Technical Error!Please try again after some time!' );
      }
      return Promise.reject(error);
    }
  );