import React from 'react'
import styles from '../styles/TradingFeatures.module.css'
import greenArrow from '../../../assets/image/landing-images/green_arrow.png'
import marginTradingIcon from '../../../assets/image/landing-images/increasement 1.png'
import mobileTradingIcon from '../../../assets/image/landing-images/trading 1.png'
import automatedTradingIcon from '../../../assets/image/landing-images/stock-market 1.png'
import customerSupportIcon from '../../../assets/image/landing-images/customer-support 1.png'
import diverseInvestmentIcon from '../../../assets/image/landing-images/profit 1.png'
import marketIndicesIcon from '../../../assets/image/landing-images/market-analysis 1.png'
import safetyIcon from '../../../assets/image/landing-images/security 1.png'
import automizationIcon from '../../../assets/image/landing-images/automization.png'
import analyticsIcon from '../../../assets/image/landing-images/analytics.png'
import tabletImg from '../../../assets/image/landing-images/tablet_bg.png'
import girlImg from '../../../assets/image/landing-images/girl_bg.png'

const TradingFeatures = () => {
    return (
        <div className={styles.mainfeature_wrapper}>
            <section className={styles.trading_features_container}>
                <div className={styles.trading_features_header_wrapper}>
                    <div className={styles.trading_features_header}>
                        <h1>Features of <span>Trading</span></h1>
                    </div>
                    <p>Trade is essential for keeping a competitive global economy and lowers the prices of goods internationally.</p>
                </div>

                <div className={styles.trading_feature_body}>
                    <div className="row mt-5">
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={marginTradingIcon} alt="" />
                                    <h3>Margin Trading</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
                                    <button>
                                        <img src={greenArrow} alt="" />
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={mobileTradingIcon} alt="" />
                                    <h3>Mobile Trading</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
                                    <button>
                                        <img src={greenArrow} alt="" />
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={automatedTradingIcon} alt="" />
                                    <h3>Automated Trading</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
                                    <button>
                                        <img src={greenArrow} alt="" />
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={customerSupportIcon} alt="" />
                                    <h3>Customer Support</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
                                    <button>
                                        <img src={greenArrow} alt="" />
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={diverseInvestmentIcon} alt="" />
                                    <h3>Diverse Investment</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
                                    <button>
                                        <img src={greenArrow} alt="" />
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={marketIndicesIcon} alt="" />
                                    <h3>Market Indices</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
                                    <button>
                                        <img src={greenArrow} alt="" />
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles.dashed_line}></div>

            <section className={styles.trading_investment_features}>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-7'>
                        <div className={styles.inv_return_content}>
                            <h1>Maximize <br /><span>Your Investment Returns</span></h1>
                            <p>Trade is essential for keeping a competitive global economy and lowers the prices of goods internationally .</p>
                        </div>
                        <div className={styles.inv_return_footer}>
                            <button>GET STARTED</button>

                            <div className={styles.offerings_wrapper}>
                                <div className={styles.offering_container}>
                                    <img src={safetyIcon} alt="" />
                                    <span>Safety</span>
                                </div>
                                <div className={styles.offering_container}>
                                    <img src={automizationIcon} alt="" />
                                    <span>automization</span>
                                </div>
                                <div className={styles.offering_container}>
                                    <img src={analyticsIcon} alt="" />
                                    <span>analytics</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className={styles.inv_bg_wrapper}>
                            <img src={tabletImg} alt="" className={styles.inv_img1} />
                            <img src={girlImg} alt="" className={styles.inv_img2} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TradingFeatures