import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Loader.module.css';
import {selectLoadingStatus} from '../../features/loader/loaderSlice';

export const Loader = () => {

  const isLoading = useSelector(selectLoadingStatus);

  return (
    <>
        {
            isLoading ? <div className={styles.overlay}>
              <h3>LOADING...</h3>
            </div> :''
        }
    </>
    
  )
}
