import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../features/login/LoginSlice';
import dipReducer from '../features/dip/DipSlice';
import loaderReducer from '../features/loader/loaderSlice';
import trendReducer from '../features/trend/TrendSlice';
import signupReducer from '../features/signup/SignupSlice';
import watchlistReducer from '../features/watchlist/WatchlistSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    login:loginReducer,
    dip : dipReducer,
    loader : loaderReducer,
    trend : trendReducer,
    signup : signupReducer,
    watchlist : watchlistReducer
  },
});
