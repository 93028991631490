import React, { useState } from 'react'
import styles from '../styles/PricingSection.module.css'
import unlockIcon from '../../../assets/image/landing-images/unlock 1.png'
import forwardArrowIcon from '../../../assets/image/landing-images/forward-arrows.png'

const PricingSection = () => {

    const [toggle, setToggle] = useState(1);

    const updateToggle = async (id) => {
        setToggle(id)
    }

    return (
        <div>
            <section className={styles.pricing_section_container}>
                <div className={styles.pricing_top_section}>
                    <div className={styles.top_section_header}>
                        <img src={unlockIcon} alt="" />
                        <h1><span>Unlock</span> Your Trading Success.</h1>
                        <img src={forwardArrowIcon} alt="" />
                    </div>
                    <button>get started </button>
                </div>

                <div className={styles.plan_selection_header}>
                    <h6>CHOOSE YOUR best plan</h6>
                    <h1>No <span>hidden</span> fees. Simple and transparent pricing.</h1>
                    <p>Lacus purus potenti praesent nibh egestas ad mattis pulvinar erat auctor fringilla condimentum faucibus consectetur diam lacinia eleifend proin eros cubilia mauris massa vel arcu cras</p>
                </div>

                <div className={styles.pricing_details_section}>
                    <div className={styles.tab_list_wrapper}>
                        <ul className={styles.tab_list}>
                            <div className={`${styles['tab-item']} ${toggle === 1 ? styles['active'] : ' '}`} onClick={() => updateToggle(1)}>
                                <li>weekly</li>
                            </div>
                            <div className={`${styles['tab-item']} ${toggle === 2 ? styles['active'] : ' '}`} onClick={() => updateToggle(2)}>
                                <li>monthly</li>
                            </div>
                            <div className={`${styles['tab-item']} ${toggle === 3 ? styles['active'] : ' '}`} onClick={() => updateToggle(3)}>
                                <li>6 months</li>
                            </div>
                            <div className={`${styles['tab-item']} ${toggle === 4 ? styles['active'] : ' '}`} onClick={() => updateToggle(4)}>
                                <li>Yearly</li>
                            </div>
                            <div className={`${styles['tab-item']} ${toggle === 5 ? styles['active'] : ' '}`} onClick={() => updateToggle(5)}>
                                <li>2 week trial</li>
                            </div>
                        </ul>
                    </div>

                    <div className={styles.tab_content}>
                        <div className={toggle === 1 ? styles['show-tab-wrapper'] : styles['tab-wrapper']}>
                            <div className={styles.pricing_cards_wrapper}>
                                <div className={styles.pricing_card_container}>
                                    <div className={styles.pricing_discount_banner}>
                                        <span className={styles.discount_banner_text1}>5% Weekly</span>
                                    </div>
                                    <div className={styles.pricing_card_header}>
                                        <h3><span>STOCKS</span> PLAN</h3>
                                        <h1>$45/<span>month</span></h1>
                                        <p>Perfect for the new Trader</p>
                                    </div>
                                    <div className={styles.pricing_features_container}>
                                        <ul>
                                            <li>Features</li>
                                            <li>Smart Alerts</li>
                                            <li>Stock Summary + Money Levels</li>
                                            <li>Real-Time Newsfeed</li>
                                            <li>Option Flow</li>
                                            <li>Dark Pools / Block Trades</li>
                                            <li className={styles.excluded_features}>Auto Charts (AI)</li>
                                            <li className={styles.excluded_features}>Signals Dashboard</li>
                                            <li className={styles.excluded_features}>Market Overview</li>
                                            <li></li>
                                        </ul>
                                        <button>purchase</button>
                                    </div>
                                </div>
    
                                <div className={styles.pricing_card_container}>
                                    <div className={styles.pricing_discount_banner}>
                                        <span className={styles.discount_banner_text2}>15% Weekly</span>
                                    </div>
                                    <div className={styles.pricing_card_header}>
                                        <h3><span>TRADER</span> PLAN</h3>
                                        <h1>$75/<span>month</span></h1>
                                        <p>Designed for active traders</p>
                                    </div>
                                    <div className={styles.pricing_features_container}>
                                        <ul>
                                            <li>Features</li>
                                            <li>Smart Alerts</li>
                                            <li>Stock Summary + Money Levels</li>
                                            <li>Real-Time Newsfeed</li>
                                            <li>Option Flow</li>
                                            <li>Dark Pools / Block Trades</li>
                                            <li>Auto Charts (AI)</li>
                                            <li className={styles.excluded_features}>Signals Dashboard</li>
                                            <li className={styles.excluded_features}>Market Overview</li>
                                            <li></li>
                                        </ul>
                                        <button>purchase</button>
                                    </div>
                                </div>
    
                                <div className={styles.pricing_card_container}>
                                    <div className={styles.pricing_discount_banner} style={{backgroundColor: "rgba(252, 149, 2, 1)"}}>
                                        <span className={styles.discount_banner_text3}>50% Weekly</span>
                                    </div>
                                    <div className={styles.pricing_card_header}>
                                        <h3><span style={{color: "rgba(252, 149, 2, 1)"}}>PRO</span> PLAN</h3>
                                        <h1>$125/<span>month</span></h1>
                                        <p>The ultimate arsenal for the serious trader</p>
                                    </div>
                                    <div className={styles.pricing_features_container}>
                                        <ul>
                                            <li style={{color: "rgba(252, 149, 2, 1)"}}>Features</li>
                                            <li>Smart Alerts</li>
                                            <li>Stock Summary + Money Levels</li>
                                            <li>Real-Time Newsfeed</li>
                                            <li>Option Flow</li>
                                            <li>Dark Pools / Block Trades</li>
                                            <li>Auto Charts (AI)</li>
                                            <li>Signals Dashboard</li>
                                            <li>Market Overview</li>
                                            <li></li>
                                        </ul>
                                        <button style={{backgroundColor: "rgba(252, 149, 2, 1)"}}>purchase</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={toggle === 2 ? styles['show-tab-wrapper'] : styles['tab-wrapper']}>B</div>
                        <div className={toggle === 3 ? styles['show-tab-wrapper'] : styles['tab-wrapper']}>C</div>
                        <div className={toggle === 4 ? styles['show-tab-wrapper'] : styles['tab-wrapper']}>D</div>
                        <div className={toggle === 5 ? styles['show-tab-wrapper'] : styles['tab-wrapper']}>E</div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PricingSection