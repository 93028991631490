import React from 'react'
import styles from '../styles/TestimonialSection.module.css'
import TestimonialSlider from '../../../components/slider/TestimonialSlider'

const TestimonialSection = () => {
  return (
    <div>
        <section className={styles.testimonial_section_container}>
            <div className={styles.testimonial_section_header}>
                <h3><span>Traders</span> who stick with us</h3>
                <p>The high level of professionalism of the support and community was extremely refreshing and appreciated. </p>
            </div>

            <TestimonialSlider />
        </section>
    </div>
  )
}

export default TestimonialSection