import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoading : (state) => {
        state.isLoading = true;
    },
    hideLoading : (state)=>{
        state.isLoading = false;
    }
  }
});

export const selectLoadingStatus = (state) => state.loader.isLoading;
export const { showLoading, hideLoading  } = loaderSlice.actions;

export default loaderSlice.reducer;