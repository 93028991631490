import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API } from '../../config/api';
import { Util } from '../../util/util';
const initialState = {
    userObject: {}
}

export const doLogin = createAsyncThunk("login/auth",
 async (req) => {
    const response = await axios.post(API.login,req);
    return response?.data;
});


const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearSession : (state) => {
        state.userObject = {};
      }
  },
  extraReducers(builder) {
        builder
            .addCase(doLogin.fulfilled, (state, action) => {
                if(action?.payload?.data){
                    state.userObject = action.payload.data;
                }else{
                    Util.showAlert(Util.alert_types.error, 'ERROR', 'Technical Error!' );
                }
            })
    }
});

export const selectUser = (state) => state.login.userObject;
export const {clearSession  } = loginSlice.actions;


export default loginSlice.reducer;