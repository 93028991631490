import React,{useEffect} from 'react';

const Nav = () => {

  useEffect(() => {
    const navbarToggle = document.querySelector('.navbar-toggler');
    navbarToggle.addEventListener('click', () => {
    const navCollapse= document.querySelector(".navbar-collapse");
      if(navCollapse.classList.contains("collapse")){
        navCollapse.classList.remove("collapse");
      }else{
        navCollapse.classList.add("collapse");
      }
    });
  }, []);
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/">TraderPro</a>

        <button
          className="navbar-toggler"
          type="button"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
     
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        
      </div>
    </nav>
  );
};

export default Nav;
