import React from 'react'
import styles from '../styles/ServiceSection.module.css'

import graph from '../../../assets/image/landing-images/service_graph.png'
import ArrowIcon from '../../../assets/image/landing-images/service_arrow.png'

const ServiceSection = () => {
    return (
        <div>
            <section className={styles.service_main_wrapper}>
                <div className={styles.service_sub_desc}>
                    <div className={styles.service_about}>
                        <h1>
                            <span>What <span className={styles.about_styled_text}>we do.</span></span> Trading is the art and science of buying and selling financial instruments.
                        </h1>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <div className={styles.service_count}>
                                    <h1>20M<span>+</span></h1>
                                    <p>People who are joined Personal Investments</p>
                                </div>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <div className={styles.service_count}>
                                    <h1>5900<span>+</span></h1>
                                    <p>Interested people use Personal investment</p>
                                </div>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <div className={styles.service_count}>
                                    <h1>50<span>+</span></h1>
                                    <p>We cooperate with famous companies</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.service_graph_section}>
                    <img src={graph} alt="" className={styles.service_graph_img} />

                    <div className={styles.service_main_desc}>
                        <h1>Measure your investment <span>value</span> correctly</h1>
                        <p>
                            Trading in financial markets involves a wide range of strategies that traders employ to make informed decisions. From trading to swing trading and long-term investing, each strategy has its own set of principles and risk factors.
                        </p>
                    </div>
                    <div className={styles.main_desc_footer}>
                        <div className={styles.desc_footer_wrapper}><h4>Trade over <span>2450</span> Global Markets</h4></div>
                        <img src={ArrowIcon} alt="" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServiceSection