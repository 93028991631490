import Swal from 'sweetalert2';

export const Util = {
    alert_types :  {
        success : 'success',
        error : 'error',
        warning : 'warning'
    },
    showAlert : (type,title, text) => {
        Swal.fire({
            title,
            text,
            icon: type,
            confirmButtonText: 'OK'
          })
    } 
}

