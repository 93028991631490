import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from './Trend.module.css';
import { fetchTrendtocks, selectTrendStocks } from "./TrendSlice";
import { Table, Pagination } from "rsuite";
import { selectUserWatchlist, selectIndices } from "../watchlist/WatchlistSlice";
import "rsuite/dist/rsuite.min.css";
import "../../util/css/Table.css";

export const Trend = () => {

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [trendType, setTrendType] = useState('D');

  const { Column, HeaderCell, Cell } = Table;
  const dispatch = useDispatch();
  const trend_stocks = useSelector(selectTrendStocks);
  const watchlists = useSelector(selectUserWatchlist) || [];
  const indices = useSelector(selectIndices) || [];
  const [watchlistSelected, setWatchlistSelected] = useState('');
  const [indexSelected, setIndexSelected] = useState('');

  useEffect(() => {
    setIndexSelected(indices[0].indexUId);
    const req = {
      indexUId: indices[0].indexUId
    };
    dispatch(fetchTrendtocks(req));
  }, [dispatch]);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const typeMap = {
    'D' : {key: 'day' , trendKey : 'day.trend'  ,  percentagekey : 'day.percentage_diff', supportKey: 'day.support', resistanceKey: 'day.resistance'},
    'W' : {key: 'week' , trendKey : 'week.trend' , percentagekey : 'week.percentage_diff', supportKey: 'week.support', resistanceKey: 'week.resistance'},
    'M' : {key: 'month' , trendKey : 'month.trend' , percentagekey : 'month.percentage_diff', supportKey: 'month.support', resistanceKey: 'month.resistance'},
  }

  const data = trend_stocks.filter(t=>t[typeMap[trendType].key].trend!=="NA").filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const getTrendType=()=>{
    return typeMap[trendType].trendKey;
  }
  const getPercentageDiff=()=>{
    return typeMap[trendType].percentagekey;
  }

  const getSupport=()=>{
    return typeMap[trendType].supportKey;
  }

  const getResistance=()=>{
    return typeMap[trendType].resistanceKey;
  }

  const handleGroupSelection=(value, flag)=>{
    if(flag === "W"){
      setWatchlistSelected(value);
      setIndexSelected("");
    }else{
      setWatchlistSelected("");
      setIndexSelected(value);
    }
  }

  const getTrends=()=>{
    const req={};
    if(indexSelected){
      req.indexUId = indexSelected;
    }else if(watchlistSelected){
      req.watchlistUId = watchlistSelected;
    }
    dispatch(fetchTrendtocks(req));
  }

  return (
    <div className={`row ${styles.wrapper}`}>
    <div className={styles.feature_head}>
      <h3>Find the Trend</h3>
    </div>
   

    <div>
      {trend_stocks && (
        <>
        <div className="row">
        <div className="form-group col-md-3">
            <label>Watchlist</label>
            <select className="form-control" value={watchlistSelected} onChange={(event)=>handleGroupSelection(event.target.value,'W')}>
              <option></option>
              {
                watchlists.map((item,index)=>{
                  return(
                    <option key={index} value={item.watchlistUId}>{item.watchlistName}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group col-md-3">
            <label>Index</label>
            <select className="form-control" value={indexSelected} onChange={(event)=>handleGroupSelection(event.target.value,"I")}>
              <option></option>
              {
                indices.map((item,index)=>{
                  return(
                    <option key={index} value={item.indexUId}>{item.indexName}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group col-md-3">
           <input type="button" value='VIEW' onClick={getTrends} className="btn btn-success"/>
          </div>
        </div>
        
          
          <div className={styles.closing_style}>
            <span>Closing basis on</span>
            <input type="button" value='DAILY' className="btn btn-info" onClick={()=>setTrendType('D')}></input>
            <input type="button" value='WEEKLY' className="btn btn-danger" onClick={()=>setTrendType('W')}></input>
            <input type="button" value='MONTHLY' className="btn btn-warning" onClick={()=>setTrendType('M')}></input>
          </div>
          <Table data={data} cellBordered={true} height={400}>
            <Column flexGrow={1}>
              <HeaderCell>No</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  return <span>{rowIndex}</span>;
                }}
              </Cell>
            </Column>
            <Column align="center" flexGrow={3}>
              <HeaderCell>STOCK</HeaderCell>
              <Cell dataKey="stock" />
            </Column>

            <Column flexGrow={2}>
              <HeaderCell>LTP</HeaderCell>
              <Cell dataKey="ltp" />
            </Column>

            <Column flexGrow={2}>
              <HeaderCell>TREND</HeaderCell>
              <Cell dataKey={getTrendType()} />
            </Column>
 
            <Column flexGrow={2}>
              <HeaderCell>% Diff</HeaderCell>
              <Cell dataKey={getPercentageDiff()} />
            </Column>

            <Column flexGrow={2}>
              <HeaderCell>RESISTANCE</HeaderCell>
              <Cell dataKey={getResistance()}  />
            </Column>
            <Column flexGrow={2}>
              <HeaderCell>SUPPORT</HeaderCell>
              <Cell dataKey={getSupport()}  />
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              // boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["-", "limit", "|", "pager"]}
              total={trend_stocks.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </>
      )}
    </div>
  </div>
  )
}
