import React, { useRef } from 'react'
import styles from '../slider/TestimonialSlider.module.css'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
// import FeatureCard1 from '../../assets/image/landing-images/Group 136.png'
import sliderIcon from '../../assets/image/landing-images/slider-icon.png'
import starIcon from '../../assets/image/landing-images/star 1.png'

const TestimonialSlider = () => {

    const slider = useRef(null)

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={styles.testimonial_slider_wrapper}>
            <style>
                {`
                    .slick-slider .slick-list {
                        padding: 32px 0!important;
                    }

                    .slick-dots li.slick-active button:before {
                        color: rgba(22, 166, 106, 1) !important;
                    }

                    .slick-dots li button:before {
                        color: rgba(22, 166, 106, 1) !important;
                    }
                `}
            </style>

            <button onClick={() => slider.current.slickPrev()} className={styles.slider_ctrl_btn}><FaChevronLeft /></button>

            <div className={styles.slider_container}>
                <Slider ref={slider} {...settings}>
                    <div className={styles.testimonial_slider_card}>
                        <div className={styles.slider_card_body}>
                            <img src={sliderIcon} alt="" />
                            <p>So much that I've done in the past hasn't worked, but I'm glad I found you guys! I'm up 129% in 5 months!</p>
                        </div>
                        <div className={styles.slider_card_footer}>
                            <h6>ROBERT B.</h6>
                            <img src={starIcon} alt="" />
                        </div>
                    </div>
                    <div className={styles.testimonial_slider_card}>
                        <div className={styles.slider_card_body}>
                            <img src={sliderIcon} alt="" />
                            <p>Their constant innovation and better service through offering their traders a wide range of services. Their support is excellent.</p>
                        </div>
                        <div className={styles.slider_card_footer}>
                            <h6>DOUG G.</h6>
                            <img src={starIcon} alt="" />
                        </div>
                    </div>
                    <div className={styles.testimonial_slider_card}>
                        <div className={styles.slider_card_body}>
                            <img src={sliderIcon} alt="" />
                            <p>I would highly recommend TradeXcel to anyone who is looking for real investment strategies.</p>
                        </div>
                        <div className={styles.slider_card_footer}>
                            <h6>TRACIE H.</h6>
                            <img src={starIcon} alt="" />
                        </div>
                    </div>
                </Slider>
            </div>

            <button onClick={() => slider.current.slickNext()} className={styles.slider_ctrl_btn}><FaChevronRight /></button>
        </div>
    )
}

export default TestimonialSlider