import React, { useState } from 'react';
import LineChart from '../../components/chart/LineChart';
import CandleChart from '../../components/chart/CandleChart';
import './Chart.css';

const Chart =(props)=>  {
  

  return(
    <div>
       { 
        props.type === 'candle' ? <CandleChart/> : <LineChart/>
       }
    </div>
  )
}
  export default Chart;


  // return (
  //     <div>
  //       {/* The Modal */}
  //       {show && (
  //         <div className="modal fade show" style={{ display: 'block' }}>
  //           <div className="modal-dialog modal-lg">
  //             <div className="modal-content">
  //               <div className="modal-header">
  //                 {/* <h5 className="modal-title">Bootstrap Modal</h5> */}
  //                 <button type="button" className="close" onClick={()=>{}}>
  //                   <span>&times;</span>
  //                 </button>
  //               </div>
  //               <div className="modal-body">
  //                 {
  //                   props.type === 'candle' ? <CandleChart/> : <LineChart/>
  //                 }
  //               </div>
  //               <div className="modal-footer">
  //                 <button type="button" className="btn btn-secondary" onClick={()=>{}}>
  //                   Close
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );


