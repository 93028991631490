import React,{useEffect} from 'react';
import { createChart } from 'lightweight-charts';

const LineChart =() => {
  

  useEffect(() => {
   renderChart();
  }, [])
  

  /**
   * Function to render a line chart
   */
  const renderChart = ()=>{
    const line_container = document.getElementById('line-chart-container');
    const lineChart = createChart(line_container, { width: 800, height: 400 });
    const lineSeries = lineChart.addLineSeries();
    //Temp Data
    lineSeries.setData([
        { time: '2023-10-01', value: 100.00 },
        { time: '2023-10-02', value: 105.75 },
        { time: '2023-10-03', value: 108.90 },
        { time: '2023-10-04', value: 112.10 },
        { time: '2023-10-05', value: 115.25 },
      ]);
    //lineChart.timeScale().fitContent();
  }  

  return (
    <>
        <div id="line-chart-container"></div>
    </>
        
  )
}

export default LineChart