import React, { useRef } from 'react'
import styles from '../slider/FeatureSlider.module.css'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import FeatureCard1 from '../../assets/image/landing-images/Group 136.png'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
            onClick={onClick}
        />
    );
}

const FeatureSlider = () => {

    const slider = useRef(null)

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={styles.feature_slider_wrapper}>
            <button onClick={() => slider.current.slickPrev()} className={styles.slider_ctrl_btn}><FaChevronLeft /></button>

            <div className={styles.slider_container}>
                <Slider ref={slider} {...settings}>
                    <div className={styles.feature_slider_card}>
                        <img src={FeatureCard1} alt="" />
                    </div>
                    <div className={styles.feature_slider_card}>
                        <img src={FeatureCard1} alt="" />
                    </div>
                    <div className={styles.feature_slider_card}>
                        <img src={FeatureCard1} alt="" />
                    </div>
                    <div className={styles.feature_slider_card}>
                        <img src={FeatureCard1} alt="" />
                    </div>
                    <div className={styles.feature_slider_card}>
                        <img src={FeatureCard1} alt="" />
                    </div>
                </Slider>
            </div>

            <button onClick={() => slider.current.slickNext()} className={styles.slider_ctrl_btn}><FaChevronRight /></button>
        </div>
    )
}

export default FeatureSlider