import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API } from '../../config/api';
import { Util } from '../../util/util';
const initialState = {
    signupSuccess: false
}

export const doSignup = createAsyncThunk("signUp/submit",
 async (data) => {
    const response = await axios.post(API.signup, data);
    return response?.data;
});


const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers(builder) {
        builder
            .addCase(doSignup.fulfilled, (state, action) => {
                if(action?.payload?.data){
                    Util.showAlert(Util.alert_types.success, 'CONGRATS!', 'Thanks for signing up' );
                    state.signupSuccess = true;
                }else{
                    Util.showAlert(Util.alert_types.error, 'ERROR', 'Technical Error!' );
                } 
            })
    }
});

export const selectsignupSuccess = (state) => state.signup.signupSuccess;

export default signupSlice.reducer;