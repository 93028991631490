import React from 'react'
import styles from '../styles/FooterSection.module.css'
import footerLogo from '../../../assets/image/landing-images/footer_logo.png'

const FooterSection = () => {
    return (
        <div>
            <section className={styles.footer_main_container}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className={styles.footer_desc_section}>
                                <h1>Let's start your investment <span>now</span></h1>
                                <button>LOGIN</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className={styles.footer_subscribe_section}>
                                <p>Subscribe newsletter for latest stock activities.</p>
                                <div className={styles.subscription_input_wrapper}>
                                    <input type="text" placeholder='Enter Email Address' />
                                    <button>subscribe</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className={styles.footer_address_section}>
                                <img src={footerLogo} alt="" />
                                <p><span>Tel</span> : +0890-564-5644</p>
                                <p><span>Email</span> : info@tradexcel.com</p>
                                <p><span>Location</span> : House- 65/4, London</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles.footer_copyright_section}>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className={styles.copyright_text}>
                                <p>Copyright ©2024 TradeXcel. All rights reserved.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className={styles.footer_links}>
                                <p>Home  |  Features  |  Pricing  |  About Us  |  Contact Us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterSection