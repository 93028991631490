import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { doLogin, selectUser } from './LoginSlice';
import styles from './Login.module.css';
import Nav from '../../components/nav/Nav';
import Chart from '../chart/Chart';
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  //TEMP
  const [showG, setShowG] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  /**
   * Function to handle form input changes.
   * @param {*} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const login = () => {
    dispatch(doLogin(formData));
  }

  const goToSignup = () => {
    history.push('/signup')
  }

  useEffect(() => {
    if (user && Object.keys(user).length) {
      history.push('/home');
    }
  }, [user, history]);

  return (
    <div className="container-fluid">
      <div className={`row ${styles.container}`}>
        <Nav />
  
        <section className={styles.hero}>
          <div className={styles.heroContent}>
            <h1>Your Investment Journey Starts Here</h1>
            <p>Unlock the potential of your investments with FundFolio.</p>
            <button className={styles.ctaButton} onClick={goToSignup}>Go To Signup</button>
            <button className='btn btn-info' onClick={() => setShowG(true)}>Show Chart</button>
          </div>
        </section>
        {showG && <section>
          <Chart type={'candle'} />
          <input type='button' value='CLOSE' onClick={() => setShowG(false)} />
        </section>
        }
  
        <section>
          <div className='col-md-6 m-auto'>
            <h4>LOGIN PAGE</h4>
            <form>
              <div className="form-group">
                <label htmlFor="name">Email</label>
                <input type="text" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="name">Password</label>
                <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} />
              </div>
              <input type="button" className="btn btn-primary mt-2" disabled={!formData.email || !formData.password} onClick={login} value='LOGIN' />
            </form>
          </div>
        </section>
        <section className={styles.features}>
          <div className={styles.feature}>
            <h2>Feature 1</h2>
            <p>Feature 1 description goes here.</p>
          </div>
          <div className={styles.feature}>
            <h2>Feature 2</h2>
            <p>Feature 2 description goes here.</p>
          </div>
          <div className={styles.feature}>
            <h2>Feature 3</h2>
            <p>Feature 3 description goes here.</p>
          </div>
        </section>
        <section className={styles.contact}>
          <h2>Contact Us</h2>
          <p>If you have any questions, feel free to contact us.</p>
          <a href="/contact" className={styles.contactButton}>Contact Us</a>
        </section>
      </div>
    </div>
  )
}

export default Login;
