import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API } from '../../config/api';

const initialState = {
    dip_stocks: []
}

export const fetchDipStocks = createAsyncThunk("dip/list",
 async (req) => {
    const response = await axios.post(API.findDip,req);
    return response?.data;
});


const dipSlice = createSlice({
  name: 'dip',
  initialState,
  reducers: {},
  extraReducers(builder) {
        builder
            .addCase(fetchDipStocks.fulfilled, (state, action) => {
                state.dip_stocks = action.payload.data.dips;
            })
            .addCase(fetchDipStocks.rejected, (state, action) => {
                state.dip_stocks = [];

            })
    }
});

export const selectDipStocks = (state) => state.dip.dip_stocks;

export default dipSlice.reducer;

