import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API } from '../../config/api';

const initialState = {
    trend_stocks: []
}

export const fetchTrendtocks = createAsyncThunk("trend/list",
 async (req) => {
    const response = await axios.post(API.findTrend,req);
    return response?.data;
});


const trendSlice = createSlice({
  name: 'trend',
  initialState,
  reducers: {},
  extraReducers(builder) {
        builder
            .addCase(fetchTrendtocks.fulfilled, (state, action) => {
                state.trend_stocks = action.payload.data.trends;
            })
            .addCase(fetchTrendtocks.rejected, (state) => {
                state.trend_stocks = [];
            })
    }
});

export const selectTrendStocks = (state) => state.trend.trend_stocks;

export default trendSlice.reducer;

