import React from 'react'
import styles from './InsightsCard.module.css'
import kotakImage from '../../assets/image/dashboard-images/kotak_logo.png'
import upTrendIcon from '../../assets/image/dashboard-images/01.png'
import downArrow from '../../assets/image/dashboard-images/image 8.png'

const InsightsCard1 = () => {
    return (
        <div className={styles.insights_card_wrapper}>
            <div className={styles.insights_card_top}>
                <h4>Kotak</h4>
                <div className={styles.insights_logo_wrapper}>
                    <img src={kotakImage} alt="" />
                </div>
            </div>
            <div className={styles.card_dashed_line}></div>
            <div className={styles.insights_card_body}>
                <div className={styles.card_body_desc}>
                    <h5>LTP :</h5>
                    <p>988.85</p>
                </div>
                <div className={styles.card_body_desc}>
                    <h5>Next Resistence :</h5>
                    <p>1000.05</p>
                </div>
            </div>
            <div className={styles.card_dashed_line}></div>
            <div className={styles.insights_card_footer}>
                <div className={styles.card_footer_trend}>
                    <img src={upTrendIcon} alt="" />
                    <p>In Uptrend</p>
                </div>
                <img src={downArrow} alt="" />
            </div>
        </div>
    )
}

export default InsightsCard1