import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API } from '../../config/api';
import { Util } from '../../util/util';

const initialState = {
    user_watchlist: [],
    available_stock_list : [],
    indices : []
}


//Get the user saved watchlist
export const getUserWatchlist = createAsyncThunk("watchlist/list/user",
 async () => {
    const response = await axios.get(API.getUserWatchList);
    return response?.data;
});

//Get all available stocks for the user to add any.
export const getAvailableStocks = createAsyncThunk("watchlist/list/all",
 async () => {
    const response = await axios.get(API.getAllStocksForWatchlist);
    return response?.data;
});

//Get all available indices
export const getAvailableIndices = createAsyncThunk("watchlist/indices",
async () => {
    const response = await axios.get(API.getIndices);
    return response?.data;
});

//Update the user watchlist.
export const updateUserWatchlist = createAsyncThunk("watchlist/update/user",
async (obj,{dispatch}) => {
   const response = await axios.patch(API.updateUserWatchList+obj.id, {stockId: obj.stock.stockId});
   dispatch(getUserWatchlist());
   return response?.data;
});

//Delete
export const deleteFromUserWatchlist = createAsyncThunk("watchlist/update/user",
async (obj,{dispatch}) => {
   const response = await axios.delete(API.updateUserWatchList+obj.id+"/"+obj.stock, {stockId: obj.stock.stockId});
   dispatch(getUserWatchlist());
   return response?.data;
});





const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {},
  extraReducers(builder) {
        builder
            .addCase(getUserWatchlist.fulfilled, (state, action) => {
                state.user_watchlist = action.payload?.data?.watchlists || [];
            })
            .addCase(getUserWatchlist.rejected, (state) => {
              state.user_watchlist=[];
            })
            .addCase(getAvailableStocks.fulfilled, (state, action) => {
                state.available_stock_list = action.payload?.data?.stocks || [];
            })
            .addCase(getAvailableStocks.rejected, (state) => {
              state.available_stock_list=[];
            })
            .addCase(updateUserWatchlist.fulfilled, (state, action) => {
                Util.showAlert(Util.alert_types.success, 'SUCCESS', 'Watchlist updated' );
            })
            .addCase(updateUserWatchlist.rejected, (state) => {
                Util.showAlert(Util.alert_types.success, 'ERROR', 'Watchlist update failed' );
            })
            .addCase(getAvailableIndices.fulfilled, (state, action) => {
                state.indices = action.payload?.data?.indices || [];
            })
            .addCase(getAvailableIndices.rejected, (state) => {
                state.indices=[];
            })
    }
});

export const selectUserWatchlist = (state) => state.watchlist.user_watchlist;
export const selectAllStocksForWatchlist = (state) => state.watchlist.available_stock_list;
export const selectIndices = (state) => state.watchlist.indices;

export default watchlistSlice.reducer;

