export const API = {
    login : "api/auth/login",
    getUserWatchList : "/api/watchlists",
    findDip : "api/dips/list",
    findTrend : "api/trends/list",
    signup : "api/users",
    logout : "api/auth/logout",
    getAllStocksForWatchlist : "api/stocks",
    updateUserWatchList : "api/watchlists/",
    getIndices : "/api/stocks/indices"

}