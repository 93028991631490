import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import styles from '../styles/Navbar.module.css'
import navbarLogo from '../../../assets/image/landing-images/navbar-logo.png'
import { FiChevronDown } from "react-icons/fi";
import { useState } from 'react';
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";

const Navbar = () => {

    const [navbarBackground, setNavbarBackground] = useState('rgb(89, 85, 239)');
    const [textColor, setTextColor] = useState('white');
    // const [imageSrc, setImageSrc] = useState(nixvpn2);
    const [hamburgerBtnColor, setHamburgerBtnColor] = useState('white');
    const [navbarBoxShadow, setNavbarBoxShadow] = useState('none');

    const [showSidebar, setShowSidebar] = useState(false);

    const [showFeaturesOptions, setShowFeaturesOptions] = useState(false);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const closeSidebar = () => {
        setShowSidebar(false);
    };

    const toggleFeaturesAccordion = () => {
        setShowFeaturesOptions(!showFeaturesOptions);
    };

    const changeBackgroundAndTextOnScroll = () => {
        if (window.scrollY > 0) {
            setNavbarBackground('rgb(255,255,255)');
            setTextColor('black');
            // setImageSrc(logo);
            setHamburgerBtnColor('black');

            setNavbarBoxShadow('0 3px 16px rgba(0, 0, 0, 0.1)');
        } else {
            setNavbarBackground('rgba(0, 0, 0, 0)');
            setTextColor('white');
            // setImageSrc(nixvpn2);
            setHamburgerBtnColor('white');

            setNavbarBoxShadow('none')
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackgroundAndTextOnScroll);

        // Initial call to set the text color based on the initial window size
        changeBackgroundAndTextOnScroll();
        return () => {
            window.removeEventListener('scroll', changeBackgroundAndTextOnScroll);
        };
    }, []);

    return (
        <div>
            <section className={styles.navbar_container} style={{ backgroundColor: navbarBackground, boxShadow: navbarBoxShadow }}>
                <div className={styles.navbar_wrapper}>
                    <div className={styles.navbar_logo}>
                        <button className={`${styles.hamburger_btn} ${showSidebar ? styles.active : ''}`} onClick={toggleSidebar} style={{ color: hamburgerBtnColor }}>
                            <HiMiniBars3BottomLeft />
                        </button>
                        <img src={navbarLogo} alt="" />
                    </div>

                    <ul className={styles.navbar_options}>
                        <li>
                            <Link to={''} style={{ color: textColor }}>Home</Link>
                        </li>
                        <li className={styles.features_dropdown}>
                            <div className={styles.nav_feature_option} style={{ color: textColor }}>
                                <p>Features</p>
                                <span><FiChevronDown /></span>
                            </div>
                            <ul className={styles.features_dropdown_content}>
                                <li>lorem</li>
                                <li>lorem</li>
                                <li>lorem</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={''} style={{ color: textColor }}>Pricing</Link>
                        </li>
                        <li>
                            <Link style={{ color: textColor }}>About Us</Link>
                        </li>
                        <li>
                            <Link style={{ color: textColor }}>Contact Us</Link>
                        </li>
                    </ul>

                    <Link to={'/login'} className={styles.login_link}><button className={styles.login_btn}>LOGIN</button></Link>

                    <div className={`${styles.nav_sidebar} ${showSidebar ? styles.active : ''}`}>
                        <div className={styles.close_section}>
                            <span></span>
                            <button onClick={closeSidebar}><IoClose /></button>
                        </div>

                        <ul className={styles.sidebar_options}>
                            <Link>
                                <li>Home</li>
                            </Link>
                            <li className={styles.features_accordion_wrapper}>
                                <div className={`${styles.features_accordion} ${showFeaturesOptions ? styles.accordion_active : ''}`} >
                                    <div onClick={toggleFeaturesAccordion} className={styles.features_accordion_option}>
                                        <span>Features</span>
                                        <FiChevronDown />
                                    </div>
                                    <div className={styles.features_sub_options}>
                                        <Link to={''}>Lorem</Link>
                                        <Link to={''}>Lorem</Link>
                                        <Link to={''}>Lorem</Link>
                                    </div>
                                </div>
                            </li>
                            <Link>
                                <li>Pricing</li>
                            </Link>
                            <Link>
                                <li>About Us</li>
                            </Link>
                            <Link>
                                <li>Contact Us</li>
                            </Link>
                            <Link>
                                <button className={styles.sidebar_login_btn}>LOGIN</button>
                            </Link>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Navbar